<template>
    <div>
        <h1>Payments</h1>
        <form @submit.prevent="runReport">
            <Options />
            <GroupBy />
            <Fields />
            <Results />
        </form>
    </div>
</template>
<script>
    import { store } from "@/store/Report.store.js";
    import Options from "./payment_rpt_opt";
    import GroupBy from "./utils/GroupBy";
    import Fields from "./utils/Fields";
    import Results from "./utils/Results";
    export default {
        name: 'payment_rpt',
        components: {Results, Fields, GroupBy, Options},
        data() {
            return {
                state: store.state,
                optionFields: {
                    paymentDateFrom: {val: new Date().toLocaleDateString(), qsField: '[dates][payment_date_from]'},
                    paymentDateTo: {val: new Date().toLocaleDateString(), qsField: '[dates][payment_date_to]'},
                    cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                    depositLocation: {val: 0, qsField: '[numbers][deposit_location_id]'},
                    salesPersonName: {val: ''},
                    salesPersonId: {val: 0, qsField: '[subquery][tracker_sales_user]'},
                    companyName: {val: ''},
                    companyId: {val: 0, qsField: '[numbers][p.company_id]'},
                    eft: {val: 0, qsField: '[EFT]'},
                    specialTag: {val: 0, qsField: '[numbers][d.branch_id]'},
                },
                groupByFields: {
                    payment_id: {label: 'Payment', checked: false},
                    company_name: {label: 'Company', checked: false},
                    cdi_location: {label: 'Location', checked: false},
                    deposit_location: {label: 'Deposit', checked: false},
                    payment_date: {label: 'Date', checked: false}
                },
                dataFields: {
                    tracker_id: {label: 'Order ID', checked: true},
                    distributor_po: {label: 'PO', checked: false},
                    payment_date: {label: 'Date', checked: true},
                    cdi_location: {label: 'Location', checked: true},
                    company_name: {label: 'Company', checked: true},
                    payment_id: {label: 'Payment ID', checked: true},
                    deposit_location: {label: 'Deposit Loc.', checked: false},
                    day_sale_outstanding: {label: 'DSO', checked: true},
                    invoice_date: {label: 'Invoice Date', checked: false}
                }
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Payments']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>